<template>
    <div id="introduce">
        <transition appear name="animate__animated animate__bounce" enter-active-class="animate__backInLeft"
            leave-active-class="animate__backOutLeft">
            <div class="border" v-if="inFlag">
                <div class="glass">
                    <div class="text">
                        <div v-show="textFlag">
                            <span
                                class="txt">原本住在与人类世界间有结界的偏远的精灵村（类比《桃花源记》中的桃花源），有一天因为送一个偶然迷路至此的人类小孩儿回人类世界而意外接触网络，从此沉迷人类流行文化并从遥远的山林深处跨越gate来到人类世界。</span>
                            <span class="txt">母语是一次性精灵语，擅长汉语和日语，英语有中学生水平，同时在学西班牙语和荷兰语。</span>
                            <span class="txt">右手缠有绷带，本人称其中封印者可以改变世界的力量，如果到了要解开绷带的地步，说明世界发生了需要使用右手力量的时候。</span>
                            <span
                                class="txt">喜欢关于声音的演绎（配音、唱歌等）、语言、东方、赛马娘、偶像大师系列、特摄、Vocaloid、lovelive!、同人志收集等ACG文化、木鱼（与宗教无关）和电子宠物，是个不折不扣的老二次元（虽然自称伪宅）。</span>
                            <span class="txt">喜欢念姐系和充满母性的台词，是个温柔大姐姐系的DD头子。</span>
                            <div class="phoneBox">
                                <hr id="hrzh" />
                                <span>· 赛马娘忠实粉丝，直播时曾大喊“阿船我好喜欢你啊，为了你我要……”</span>
                                <hr />
                                <span>· 由于有与植物共情的能力，因此虽然是精灵，却是肉食爱好者。</span>
                                <hr />
                                <span>· 每次扮S的时候都因为各种原因而绷不住，比如想念S台词时忘词(M实锤。</span>
                                <hr />
                                <span>· 明明不是大根(萝卜)，但大家都叫她大根。</span>
                                <hr />
                                <span>· 亲自承认了自己是憨憨。<el-link type="danger" target="_blank"
                                        href="https://www.bilibili.com/video/BV1gt4y1E7L3/">点击查看证据</el-link></span>
                                <hr />
                                <span>· 生日：4月25日,2022年2月19日17时首播</span>
                                <hr />
                                <span>· 喜欢看男孩子击剑。</span>
                            </div>
                        </div>
                        <el-button type="danger" icon="el-icon-refresh" circle class="butRefresh"
                            @click="divRefresh()"></el-button>
                        <div id="musicHS" :style="{ width: `${musicHSW}px` }" v-show="setFlag">
                            <el-button type="success" v-show="musicHSFlag" icon="el-icon-headset" circle
                                @click="playHS"></el-button>
                            <el-button type="success" v-show="!musicHSFlag" icon="el-icon-video-pause" circle
                                @click="playHS"></el-button>
                            <span v-show="!musicHSFlag">海色——佐保姬夕映(翻唱)</span>
                            <el-slider v-model="value3" :show-tooltip="false" v-show="!musicHSFlag" class="sliderPos"
                                @change="mvh"></el-slider>
                            <audio src="./music/HS.mp3" ref="HSaudio"></audio>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight"
            leave-active-class="animate__fadeOutRight">
            <sett id="pcSet" v-if="inFlag" v-show="setFlag"></sett>
        </transition>




    </div>
</template>

<script>
import sett from "./set.vue"
export default {
    name: 'vueIntroduce',
    components: {
        sett
    },
    data() {
        return {
            inFlag: true,
            musicHSFlag: true,
            musicHSW: 70,
            musicHSMaxW: 330,
            value3: 0,
            timeInterval: null,
            textFlag: true,
            setFlag: true
        };
    },
    methods: {
        mvh() {
            this.$refs.HSaudio.currentTime = this.value3;
        },
        playHS() {
            this.musicHSFlag = !this.musicHSFlag;
            if (this.musicHSFlag) {
                this.musicHSW = 70;
                this.$refs.HSaudio.pause();
                clearInterval(this.timeInterval);
                this.timeInterval = null;
            }
            else {
                this.musicHSW = this.musicHSMaxW;
                this.$refs.HSaudio.currentTime = 0;
                this.$refs.HSaudio.volume = 0.5;
                this.$refs.HSaudio.play();
                this.timeInterval = setInterval(() => {
                    this.value3 = this.$refs.HSaudio.currentTime;
                }, 20)
            }
        },
        divRefresh() {
            this.textFlag = !this.textFlag;
            this.setFlag = !this.setFlag;
        }
    },
    watch: {
        inFlag: {
            handler(newValue) {
                if (newValue == false) {
                    clearInterval(this.timeInterval);
                    this.timeInterval = null;
                }
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 480) {
            this.textFlag = false;
            this.musicHSMaxW = 220;
        }
        else {
            this.textFlag = true;
            this.musicHSMaxW = 330;
        }
        this.$bus.$on('divFlag2', (f) => {
            this.inFlag = f;
        })
        window.addEventListener('resize', () => {
            if (window.innerWidth <= 480) {
                this.textFlag = false;
                this.musicHSMaxW = 220;
            }
            else {
                this.musicHSMaxW = 330;
                this.textFlag = true;
                this.setFlag = true;
            }
        })
    },
    beforeDestroy() {
        clearInterval(this.timeInterval);
        this.timeInterval = null;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#introduce {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 990px;
    min-height: 695px;
}

#pcSet {
    position: absolute;
    right: 7%;
    top: 1%;
    width: 35%;
    height: 94%;
    display: flex;
}

.glass {
    border-radius: 30px;
    /* pointer-events: none; */
    width: 100%;
    height: 100%;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
    background: transparent;
    backdrop-filter: blur(15px);
    transition: 0.2s;
}

.border {
    position: absolute;
    top: 5%;
    left: 7%;
    border-radius: 30px;
    /* pointer-events: none; */
    width: 86%;
    height: 87%;
    border-left: 5px solid #67C23A;
    border-right: 5px solid #67C23A;
    padding: 10px;
}

.glass .text {
    width: 58%;
    height: 100%;
    /* border: 1px solid red; */
    padding-top: 1.5%;
    padding-left: 2%;
    margin-left: 10px;
    overflow: hidden;
}

.text span {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 24px;
    display: block;
}

.text hr {
    margin: 0px;
    border: none;
    height: 2px;
    background-color: #ffffff46;
}

.phoneBox {
    display: block;
}

#hrzh {
    margin-top: 12px;
    margin-bottom: 12px;
    border: none;
    height: 6px;
    background-color: #67C23A;
}

#musicHS {
    position: absolute;
    bottom: 15px;
    left: 15px;
    border: 5px solid white;
    height: 70px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.7);
}

#musicHS button {
    width: 40px;
    height: 40px;
    margin: 10px;
}

#musicHS span {
    margin: 10px;
}

.sliderPos {
    position: absolute;
    bottom: 0%;
    left: 65px;
    width: 200px;
    transform: translateY(45%);
}

.butRefresh {
    display: none;
    background-color: #f56c6ca2;
    border: 1px solid #f56c6c96;
}

.butRefresh:focus {
    background-color: #f56c6c6b;
    border: 1px solid #f56c6c96;
}

@media only screen and (max-width: 480px) {
    .phoneBox {
        display: none;
    }

    #introduce {
        min-width: 200px;
        min-height: 200px;
    }

    #pcSet {
        width: 90%;
        left: 5%;
        top: 16.5%;
        height: 60%;
        /* border: 1px solid red; */
    }

    .border {
        width: 92%;
        left: 4%;
        padding: 8px;
        height: 88%;
    }

    .glass .text {
        width: 100%;
        margin-left: 0px;
        padding: 10px;
        overflow: hidden;
    }

    .text span {
        font-size: 15px;
        margin: 5px;
        line-height: 22px;
    }

    #hrzh {
        margin-top: 12px;
        border: none;
        height: 3px;
        background-color: #67C23A;
    }

    .text hr {
        margin: 0px;
        border: none;
        height: 1px;
        background-color: #ffffff46;
    }

    #musicHS {
        position: absolute;
        bottom: 10px;
    }

    .butRefresh {
        position: absolute;
        right: 25px;
        top: calc(77% + 42px);
        display: block;
    }

}
</style>
<style>
#musicHS .el-slider__button {
    border: 2px solid #67C23A;
}

#musicHS .el-slider__bar {
    background-color: #67C23A;

}
</style>